<template>
  <div class="container">
    <h4 class="font-weight-bold text-center">Datos del cliente</h4>

    <div class="rounded shadow mt-4">
      <div class="p-4">
        <div class="justify-content-between d-flex">
          <div>
            <h6 class="mb-0">
              Favor seleccionar que tipo de persona adquiere el seguro
            </h6>
          </div>
          <div class="custom-control custom-radio">
            <div class="form-group mb-0">
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  v-model="$v.form.personType.$model"
                  type="radio"
                  id="customRadioInline1"
                  value="1"
                  name="customRadioInline"
                  class="custom-control-input"
                  required
                />
                <label class="custom-control-label" for="customRadioInline1"
                  >Persona Física</label
                >
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <hr v-if="$v.form.personType.$model == 'customRadioInline2'" />
        <div class="row">
          <div class="col-md-12">
            <form>
              <div class="row">
<!--                 
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="h6">País de Nacimiento:</label>
                    <select
                      v-model="form.selectedCountry"
                      class="form-control form-select"
                      aria-label="Default select example"
                      :required="isAmountExceeded"
                    >
                      <option value="" disabled>seleccionar</option>
                      <option v-for="(states, idx) in countryList"
                      :key="idx"
                      :value="states.countryId"> {{ states.countryName }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="h6">Entidad federativa de nacimiento:</label>
                    <select
                        v-model="form.selectedState"
                        class="form-control form-select"
                        aria-label="Seleccionar estado"
                        :disabled="form.selectedCountry !== 151"
                        :required="isAmountExceeded"
                      >
                        <option value="" disabled>Seleccionar</option>
                        <option v-if="form.selectedCountry === 151"  v-for="(state, idx) in statesList" :key="idx" :value="state.value">{{ state.label }}</option>
                        <option v-else value="EXTRANJERO" :selected="true">Extranjero</option>
                      </select>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="h6">Tipo de Documento de Identidad:</label>
                    <select
                      v-model="form.tid"
                      class="form-control form-select"
                      aria-label="Default select example"
                      :required="isAmountExceeded"
                    >
                      <option value="" disabled>seleccionar</option>
                      <option value="1">INE</option>
                      <option value="2">PASAPORTE</option>
                      <option value="3">CEDULA PROFESIONAL</option>
                      <option value="4">LICENCIA DE CONDUCIR</option>
                      <option value="5">CARTILLA DE SERVICIO MILITAR</option>
                      <option value="6">
                        CERTIFICADO DE MATICULA CONSULAR
                      </option>
                      <option value="7">
                        TARJETA UNICA DE IDENTIDA MILITAR
                      </option>
                      <option value="8">INAPAM ix. FM2/FM3</option>
                    </select>
                  </div>
                </div>
                 -->
                <div class="col-md-4" v-if="form.tid == '1'">
                  <div class="form-group">
                    <label class="h6">Clave de Elector:</label>
                    <input
                      class="form-control"
                      type="text"
                      aria-label="Default select example"
                      maxlength="18"
                      :required="isAmountExceeded && form.tid == '1'"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="form.selectedCountry != 151 && form.selectedCountry != ''"
                >
                  <div class="form-group">
                    <label class="h6"
                      >Número de identificación fiscal extranjero:</label
                    >
                    <input
                      type="number"
                      class="form-control"
                      aria-label="Default select example"
                      :required="isAmountExceeded && form.selectedCountry != 151"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>RFC:</label>
                    <div class="position-relative">
                      <input
                        v-model="$v.form.RFC.$model"
                        name="RFC"
                        id="RFC"
                        type="text"
                        placeholder="Ingrese su RFC:"
                        class="form-control"
                        required=""
                        v-mask="'AAAA-######XXX'"
                      />
                      <div
                        class="invalid-feedback"
                        v-bind:class="{ 'd-block': $v.form.RFC.$error }"
                      >
                        <span>Proporcione un valor válido</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="h6">Régimen fiscal:</label>
                    <select
                      v-model="form.regimeTax"
                      class="form-control form-select"
                      aria-label="Default select example"
                      :required="isAmountExceeded"
                    >
                      <option value="" selected>seleccionar</option>
                      <option v-for="item in regimeList" :key="item" :value="item.value">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="h6">Uso de CFDI:</label>
                    <select
                      v-model="form.cfdi"
                      class="form-control form-select"
                      aria-label="Default select example"
                      :required="isAmountExceeded"
                    >
                      <option value="" selected>seleccionar</option>
                      <option v-for="item in cfdiList" :key="item" :value="item.value">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr />
                  <div class="form-group">
                    <a-checkbox
                      v-model="geolocation"
                      :indeterminate="indeterminate"
                      @change="onCheckAllChange"
                      :required="isAmountExceeded"
                    >
                      <label class="h6">Permiso Geolocalización</label>
                    </a-checkbox>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mt-5 mb-4"><b>Datos de la vivienda a Asegurar</b>​</h5>
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label class="h6">Años de construcción:</label>
                <select
                  v-model="$v.form.yearsOfConstruction.$model"
                  class="form-control form-select"
                  aria-label="Default select example"
                >
                  <option value="1" selected>1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Calle:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.addressStreet.$model"
                    name="addressStreet"
                    id="addressStreet"
                    type="text"
                    placeholder="Ingrese calle"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressStreet.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Num exterior:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.addressNE.$model"
                    name="addressNE"
                    id="addressNE"
                    type="text"
                    placeholder="Num Exterior"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressNE.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Num interior:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.addressNI.$model"
                    name="addressNI"
                    id="addressNI"
                    type="text"
                    placeholder="Ingrese interior"
                    class="form-control"
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.addressNI.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <label>País:</label>
                <select name="" class="form-control" disabled>
                  <option value="selected">México</option>
                </select>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label>Colonia:</label>
                <div class="position-relative">
                  <select
                    v-model="$v.form.suburb.$model"
                    name="suburb"
                    id="suburb"
                    type="text"
                    placeholder="Colonia:"
                    class="form-control"
                    @change="updateLocations"
                  >
                    <option
                      v-for="(option, idx) in this.data"
                      v-bind:key="idx"
                      :value="data[idx].zones.name_colonia"
                    >
                      {{ option.zones.name_colonia }}
                    </option>
                  </select>
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.suburb.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <label>Municipio:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.municipality.$model"
                    name="municipality"
                    id="municipality"
                    type="text"
                    placeholder="Ingrese municipio :"
                    class="form-control"
                    disabled
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.municipality.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Estado:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.state.$model"
                    name="state"
                    id="state"
                    type="text"
                    placeholder="Ingrese Estado :"
                    class="form-control"
                    required=""
                    disabled
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.state.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>C.P.:</label>
                <div class="position-relative">
                  <input
                    v-model="$v.form.CP.$model"
                    name="CP"
                    id="CP"
                    type="text"
                    placeholder="C.P.:"
                    class="form-control"
                    disabled
                  />
                  <div
                    class="invalid-feedback"
                    v-bind:class="{ 'd-block': $v.form.CP.$error }"
                  >
                    <span>Proporcione un valor válido</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div>
      <div class="rounded shadow mt-4">
        <div class="p-4 border-bottom">
          <h5 class="mb-0">Información complementaria</h5>
        </div>
        <div class="p-4">
          <div class="d-flex justify-content-between pb-4">
            <h6 class="mb-0">¿El cliente actua por su propia cuenta?</h6>
            <div class="custom-control custom-switch">
              <input
                v-model="$v.form.agree.$model"
                type="checkbox"
                id="customSwitch2"
                class="custom-control-input"
                checked
              /><label for="customSwitch2" class="custom-control-label"></label>

              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.agree.$error }"
              >
                <span>Valor requerido para continuar</span>
              </div>
            </div>
          </div>
          <div class="media d-flex justify-content-between py-4 border-top">
            <div class="row">
              <div class="col-md-8">
                <h6 class="mb-3">
                  ¿Cuánto estima gastar anualmente en seguros con AIG?
                </h6>
              </div>
              <div class="col-md-12 text-center">
                <div class="custom-control custom-radio custom-control-inline">
                  <div class="form-group mb-0">
                    <input
                      type="radio"
                      id="customRadio4"
                      name="customRadio1"
                      class="custom-control-input"
                      checked
                    />
                    <label class="custom-control-label" for="customRadio4">
                      Hasta 4 veces el monto del seguro inicial</label
                    >
                  </div>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                  <div class="form-group mb-0">
                    <input
                      type="radio"
                      id="customRadio5"
                      name="customRadio1"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="customRadio5">
                      Más de 4 veces el monto del seguro inicial
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="media d-flex justify-content-between py-4 border-top">
            <h6
              class="mb-0 text-justify"
              style="width: 100%; padding-right: 80px"
            >
              Manifiesto bajo protesta de decir verdad, que he verificado los
              datos y la información aquí proporcionada, mismo que fueron
              obtenidos mediante el llenado de este formulario de entrevista
              digital.
            </h6>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="$v.form.agree2.$model"
                id="customSwitch3"
                class="custom-control-input"
                checked
              /><label for="customSwitch3" class="custom-control-label"></label>

              <div
                class="invalid-feedback"
                v-bind:class="{ 'd-block': $v.form.agree2.$error }"
              >
                <span>Valor requerido para continuar</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      <a
        href="javascript:void"
        @click="nextStep()"
        class="btn btn-sm btn-primary"
      >
        Continuar
        <arrow-right-icon size="1.5x" class="custom-class"></arrow-right-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { resolve } from "path";
import { reject } from "q";
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import countryList from "../../utils/countryList";
import statesList from "../../utils/statesList";
import regimeList from "../../utils/RegimeList";
import cfdiList from "../../utils/cfdiList";
import { convertToDollar } from "@/services/costco.service";

const isTrue = (value) => value === true;

export default {
  mixins: [validationMixin],
  data() {
    const notyf = new Notyf();
    return {
      notyf,
      fields: ["Nombre", "apellido", "email", "contratante"],
      form: {
        personType: "1",
        fideicomiso: "",
        geolocationCode: "",
        tid: "",
        selectedState: "",
        selectedCountry: "",
        originResources: "",
        regimeTax: "",
        cfdi: "",
        yearsOfConstruction: "",
        addressStreet: "",
        addressNE: "",
        addressNI: "",
        country: "MEXICO",
        suburb: "",
        CP: "",
        municipality: "",
        state: "",
        RFC: "",
        agree: true,
        agree2: true,
      },
      geolocation: true,
      indeterminate: false,
      
      data: null,
      statesList,
      countryList,
      regimeList,
      cfdiList,
      usdPremiumValue: 0,
      isAmountExceeded: false,
    };
  },
  mounted() {
    this.checkDataZone();
    this.onCheckAllChange(this.geolocation)
  },
  

  validations: {
    form: {
      // name: { required },
      // lastNameFa: { required },
      // lastNameMa: { required },
      // email: { required, email },
      // contractor: { required },
      // livingPlace: { required },
      // phone: { required, numeric },
      personType: {required},
      yearsOfConstruction: { required },
      addressStreet: { required },
      addressNE: { required },
      addressNI: {},
      country: { required },
      suburb: { required },
      CP: { required, numeric },
      municipality: { required },
      state: { required },
      RFC: { required },
      // avg: { required, numeric },
      agree: { isTrue },
      agree2: { isTrue },
    },
  },

  methods: {
    nextStep() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.$gtag.event("data_customer_error", {
          event_category: "quote",
          event_label: "Data customer error",
          value: 1,
        });

        return;
      }

      localStorage.setItem("formStep2", JSON.stringify(this.form));

      this.$gtag.event("data_customer_completed", {
        event_category: "quote",
        event_label: "Data customer completed",
        value: 1,
      });

      this.$emit("next");
    },
    checkDataZone() {
      const dataZone = localStorage.getItem("zones") || null;
      const zones = JSON.parse(dataZone);
      this.form.CP = this.query.postalCode;
      this.data = zones;
      this.getRatesValues();
    },
    getRatesValues(){
      const planData = JSON.parse(localStorage.getItem("p"));
      const ratesData = JSON.parse(localStorage.getItem("ratesValues"));
      if (planData.cat == "CAT") {
        this.usdPremiumValue = ratesData.convertionValueCat
      } else {
        this.usdPremiumValue = ratesData.convertionValueNoCat
      }
      this.isAmountExceeded = this.usdPremiumValue >= 2500;
    },
    reloadData(idx) {
      this.form.municipality = this.data[idx].zones.name_municipio;
      this.form.state = this.data[idx].zones.estado;
    },
    updateLocations() {
      const state = this.data.find(
        (e) => e.zones.name_colonia == this.form.suburb
      );
      this.form.municipality = state?.zones?.name_municipio;
      this.form.state = state?.zones?.estado;
    },
    async onCheckAllChange(e) {
      this.indeterminate = true;
      this.activeLocation(e)
        .then(({ state, latitude, longitude }) => {
          if (state) this.indeterminate = false;
          this.form.geolocationCode = latitude + ", " + longitude;
        })
        .catch((state) => {
          this.geolocation = state;
          this.indeterminate = state;
          this.form.geolocationCode = "-";
          this.notyf.error("No se puede recuperar tu ubicación");
        });
    },
    async activeLocation(e) {
      return new Promise((resolve, reject) => {
        function success(position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ state: true, latitude, longitude });
        }

        function error() {
          reject(false);
        }

        if (!navigator.geolocation) {
          this.notyf.error(
            "La geolocalización no es soportada por este navegador web"
          );
          reject(false);
        } else if (e) {
          navigator.geolocation.getCurrentPosition(success, error);
        } else {
          this.notyf.error("Debe activar la geolocalización");
          reject(false);
        }
      });
    },
  },

  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    'form.selectedCountry'(value) {
      if (value !== 151) {
        this.form.selectedState = "EXTRANJERO";
      }
    },
  },
};
</script>

<style>
.rowing {
  border: 1px solid black;
}
</style>